body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #101f36;
  color: #FFFFFF;
}
.profile-sidebar {
  background-color: #101f36;
  color: #FFFFFF;
  width: 350px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  padding: 20px;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%); /* Hidden by default */
}

.profile-sidebar.open {
  transform: translateX(0); /* Show sidebar */
}

.profile-sidebar h2 {
  color: #FFFFFF;
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  z-index: 999; 
  display: flex;
  justify-content: flex-end; 
  height: 60px;
}


.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 20px;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap; 
}

.hero-content {
  max-width: 700px;
  margin-top: 25%;
  flex: 1; /* Ensure the content takes up the available space */
  text-align: center; /* Center text for mobile */
}

.hero-content h1 {
  font-size: 2.5rem; /* Adjust for smaller screens */
  color: #FFFFFF;
  margin: 0;
  word-wrap: break-word; /* Ensure long words wrap */
}

.hero-content p {
  font-size: 1.2rem;
  color: #A0AEC0;
  margin: 20px 0;
}

.hero-buttons {
  margin-top: 20px;
}

.hero-buttons .btn {
  margin-right: 20px;
  padding: 12px 24px;
  font-size: 1rem;
  color: #FFFFFF;
  border: 2px solid #FF007F;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-bottom: 10px;
}

.hero-buttons .btn.primary {
  background-color: #FF007F;
}

.hero-buttons .btn:hover {
  background-color: #FF007F;
  color: #FFFFFF;
}

.hero-image {
  width: 100%;
  max-width: 250px;
  position: relative;
  margin-left: 10%;
  margin-top: 20%;
}

.hero-image img {
  width: 100%;
  border-radius: 10px;
}

.quote-box {
  position: absolute;
  bottom: -10px;
  left: -30px;
  background-color: #2D3748;
  padding: 10px 20px;
  color: #A0AEC0;
  font-size: 1rem;
  border-radius: 8px;
  width: 280px;
  text-align: center;
}

/* Projects Page */
.projects-page {
  padding: 60px;
  color: #FFFFFF;
  max-width: 700px;
  overflow-y: auto;
  min-height: 100vh;
}

.projects-page h1 {
  color: #FF007F;
  font-size: 1.8em;
}

.projects-page ul {
  list-style-type: none;
}

.projects-page li {
  margin: 10px 0;
  font-size: 1.2rem;
}

.project-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
  margin-top: 150px;
}

.project-buttons .btn {
  margin-right: 20px;
  padding: 12px 24px;
  font-size: 1rem;
  color: #FFFFFF;
  border: 2px solid #FF007F;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.project-buttons .btn.primary {
  background-color: #FF007F;
}

.project-buttons .btn:hover {
  background-color: #FF007F;
  color: #FFFFFF;
}

.resume-viewer {
  padding: 60px;
  text-align: center;
}

.resume-viewer h1 {
  color: #FF007F;
}

/* .pdf-container {
  width: 100%;
  max-width: 800px;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
}

canvas {
  max-width: 100%;
}

.pdf-container::-webkit-scrollbar {
  width: 10px;
}

.pdf-container::-webkit-scrollbar-track {
  background: #0A192F;
}

.pdf-container::-webkit-scrollbar-thumb {
  background-color: #FF007F;
  border-radius: 10px;
} */
.pdf-container {
  width: 100%; /* Ensure it takes full width */
  max-width: 800px;
  max-height: 70vh;
  overflow-y: auto;
  cursor: pointer;
  margin: 0 auto; /* Center align */
}

canvas {
  width: 100% !important; /* Make the canvas responsive */
  height: auto !important;
}

/* Custom scroll for the PDF container */
.pdf-container::-webkit-scrollbar {
  width: 10px;
}

.pdf-container::-webkit-scrollbar-track {
  background: #0A192F;
}

.pdf-container::-webkit-scrollbar-thumb {
  background-color: #FF007F;
  border-radius: 10px;
}

.pdf-container::-webkit-scrollbar-thumb:hover {
  background-color: #FF3399;
}

.project-link {
  display: block;
  padding: 20px;
  margin: 10px 0;
  background-color: #1A202C;
  color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
}

.project-link:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);

}

.project-link h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: underline;
}


.project-link h3:hover {
  transform: translateY(3px);
  color: #FF007F;
}

.project-link p {
  margin: 5px 0 0;
  font-size: 0.9rem;
}

.ticket-type-btn {
  width: 300px;
  height: 300px;
  font-size: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
}

.ticket-type-btn:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.modal-content, .offcanvas-body, .offcanvas-header {
  background-color: #1A202C;
  color: #FFFFFF;
}

.btn-primary, .btn-secondary {
  background-color: #FF007F;
  border-color: #FF007F;
}

.btn-primary:hover, .btn-secondary:hover {
  background-color: #FF3399;
  border-color: #FF3399;
}
.btn-outline-primary {
  background-color: transparent;
  color: #FF007F;
  border-color: #FF007F;
}

.btn-outline-primary:hover {
  background-color: #FF007F;
  color: white;
  border-color: #FF007F;
}
.form-control {
  background-color: #2D3748;
  color: #FFFFFF;
}

.form-control:focus {
  background-color: #2D3748;
  color: #FFFFFF;
  border-color: #FF007F;
}

.form-control.is-invalid {
  background-color: #2D3748;
}

.form-control.is-valid {
  background-color: #2D3748;
}

.form-control::placeholder {
  color: #A0AEC0 !important;
  opacity: 1;
}

.view-ticket {
  padding: 20px;
  background-color: #1A202C;
}

.card {
  background-color: #2D3748;
  border: none;
}

.list-group-item {
  background-color: #2D3748;
  border: none;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.comment {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #4A5568;
}

.comment.user {
  background-color: #2D3748;
}

.comment.admin {
  background-color: #4A5568;
  margin-top: 5%;
}

.text-white {
  color: #FFFFFF;
}

.form-control {
  background-color: #2D3748;
  color: #FFFFFF;
}

.form-control:focus {
  background-color: #2D3748;
  color: #FFFFFF;
  border-color: #FF007F;
}


.button-submit {
  float: right;
  background-color: #FF007F;
  border: none;
  color: #FFFFFF;
}

.button-submit:hover {
  background-color: #FF3399;
}

.modal-body {
  max-height: 70vh;
  overflow-y: auto;
}

.modal-body::-webkit-scrollbar {
  width: 8px;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: #FF007F;
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-track {
  background-color: #2D3748;
}

.github-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #FF007F;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 0.65em;
}

.github-link:visited {
  background-color: #4A5568;
}

.github-link:hover {
  background-color: #FF007F;
  transform: translateY(-2px);
  text-decoration: underline;
}

.project-title {
  cursor: pointer;
  font-size: 1.6rem;
  color: #ffffff;
  transition: color 0.3s ease, transform 0.3s ease;
}

.project-title:hover {
  color: #FF007F;
  transform: translateY(-2px);
}

.project-link p {
  color: #A0AEC0;
  margin: 10px 0;
}

.custom-fullscreen-modal .modal-dialog {
  width: 100vw !important;
  height: 100vh !important;
  margin: 0;
  padding: 0;
  max-width: 100% !important;
}

.custom-fullscreen-modal .modal-content {
  height: 80vh !important;
  width: 80vw !important;
  border-radius: 0;
  padding: 20px;
  box-sizing: border-box;
  margin-left: 10vw;

}

.custom-fullscreen-modal .modal-body {
  overflow-y: auto;
  /* height: calc(100vh - 60px); */
  width: 100%;
}

.sidebar {
  background-color: #F7F7F7;
  padding: 20px;
  height: 100%;
}

.sidebar-header h4 {
  font-size: 1.2rem;
  font-weight: bold;
}

.sidebar-btn {
  background-color: #FF007F;
  color: #ffffff;
  border: none;
  padding: 10px;
  text-align: left;
}

.sidebar-btn:hover {
  background-color: #ffffff;
  color: #FF007F;
}

.text-pink {
  color: #FF007F;
}

.list-group-item {
  background-color: #EDF2F7;
  border: none;
  color: #2D3748;
  margin-bottom: 10px;
}

.list-group-item:hover {
  background-color: #E2E8F0;
}

.tile-card {
  background-color: #1A202C;
  color: white;
  border-radius: 8px;
  height: 100%;
}

.tile-title {
  color: #FF007F;
  font-weight: bold;
  margin-bottom: 10px;
}

.tile-description {
  color: #A0AEC0;
}

.tile-icon {
  color: #FF007F;
}

.add-more-tile {
  background-color: #EDF2F7;
}

.add-more-tile .tile-title,
.add-more-tile .tile-icon {
  color: #A0AEC0;
}

.add-more-tile:hover {
  background-color: #E2E8F0;
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    align-items: center;
  }

  .project-buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
    margin-top: 20px;
  }
  .hero-content {
    margin-top: 20px;
    max-width: 90%;
    text-align: center;
  }

 
  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-content p {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  .hero-content h1 {
    font-size: 1.8rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .hero-image {
    max-width: 100%;
  }
}


.pink-container {
  background-color: #ff007f; /* Pink color */
  border-radius: 5px;
  padding: 10px;
}

.blue-container {
  background-color: #007bff; /* Dark blue color */
  border-radius: 5px;
  padding: 10px;
}

.clickable {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.clickable:hover {
  transform: translateY(-5px);
}

.training-calendar {
  max-height: 60vh; /* Adjust the height as needed */
  overflow-y: auto;
}

.training-calendar::-webkit-scrollbar {
  width: 10px;
}

.training-calendar::-webkit-scrollbar-thumb {
  background-color: #ff007f; /* Custom pink scroll bar */
  border-radius: 10px;
}


/* For screens less than 768px wide */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    align-items: center;
  }

  /* Ensure the image is first */
  .hero-image {
    order: 1; /* This places the image first */
    width: 60%; /* Make the image smaller on mobile */
    margin-top: 20px; /* Add some spacing */
    display: none;
  }

  /* Ensure text comes next */
  .hero-content {
    order: 2; /* This places the text after the image */
    margin-top: 10px;
    text-align: center; /* Ensure the text is centered on mobile */
  }

  /* Buttons come last */
  .hero-buttons {
    order: 3; /* This places the buttons last */
    margin-top: 20px; /* Ensure there's space between the text and buttons */

  }
}

/* For even smaller screens (576px or less) */
@media (max-width: 576px) {
  .hero-image {
    width: 60%; /* Make the image take up full width on very small screens */
    display: none;
  }
}

/* Ensure the modal is full-width on mobile */
@media (max-width: 768px) {
  .pdf-container {
    width: 100%; /* Ensure full width on mobile */
    margin: 0;
    padding: 0;
  }
.projects-page {
  padding: 0;
  overflow-y: scroll;
  height: 100vh;
}
  /* Modal responsiveness for mobile */
  .modal-dialog {
    width: 100% !important;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-body {
    max-height: 80vh;
    overflow-y: auto;
    padding: 0;
    margin: 0;
  }

  canvas {
    width: 100% !important; /* Ensure canvas is responsive on mobile */
    height: auto !important;
  }
}


/* Mobile-friendly table conversion */
@media (max-width: 768px) {
  table thead {
    display: none; /* Hide the header on mobile */
  }
  table tbody tr {
    display: block; /* Make each row a block on mobile */
    margin-bottom: 10px;
  }
  table tbody tr td {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ddd; /* Optional: Add a bottom border to separate rows */
    text-align: right; 
  }
  table tbody tr td::before {
    content: attr(data-label); /* Use data-label attribute for each cell */
    flex: 1;
    font-weight: bold;
    text-align: left;
  }
}

/* General mobile-friendly styles for NodeProjectModal */
.node-project-modal .modal-body {
  padding: 20px;
}

.node-project-modal .modal-content {
  border-radius: 8px;
  margin: 0 10px;
  padding: 15px;
}

.node-project-modal .modal-header {
  padding: 10px 15px;
  background-color: #20232a;
  border-bottom: 1px solid #444;
}

.node-project-modal .modal-title {
  font-size: 1.2rem;
  color: #fff;
}

.node-project-modal ul {
  padding-left: 20px;
  margin-bottom: 20px;
}

.node-project-modal p {
  padding: 0 10px;
  line-height: 1.5;
}


/* Adjust button width and height on mobile */
@media (max-width: 768px) {
  .node-project-modal .dropdown-toggle {
    width: 100%; /* Make the button full width */
    max-width: 300px; /* Limit the button's maximum width */
    padding: 10px 15px; /* Adjust padding for better height */
    font-size: 1rem; /* Ensure the text fits within the button */
    white-space: normal; /* Allow the text to wrap */
    word-wrap: break-word; /* Break long words */
    height: auto; /* Let the button height expand based on content */
  }

  .node-project-modal .dropdown-menu {
    max-height: 300px; /* Keep dropdown height controlled */
    overflow-y: auto;
    width: 100%;
  }

  .node-project-modal .dropdown-item {
    white-space: normal;
    padding: 10px 15px;
    height: auto;
    font-size: 0.9rem;
  }
}


/* Add this CSS rule to target the custom scrollbars */

.custom-scrollbar {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: rgba(255, 0, 128, 0.2) transparent; /* Firefox */
}

/* Webkit browsers (Chrome, Safari, etc.) */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px; /* Very skinny scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Transparent track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 0, 128, 0.2); /* Pink color for the scrollbar thumb */
  border-radius: 10px; /* Rounded edges for a sleeker look */
  border: 2px solid transparent; /* Ensure thumb is not too thick */
}

/* Hover effect to make the scrollbar slightly more noticeable */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 0, 128, 0.2); /* A slightly brighter pink on hover */
}


.attendance-user-tile {
  background-color: #1f02472d;
  box-shadow: black 3px 3px 3px;
}

.crypto-tracker-modal .modal-body {
  padding: 1.5%;
}

.attendance-control-box {
  background-color: #14081f;
  padding: 1rem;
  border-radius: 7px;
  margin-bottom: 1rem;
  box-shadow: black 3px 3px 3px;
}

/* Custom pink checkbox styling */
.custom-checkbox .form-check-input:checked {
  background-color: #FF007F;
  border-color: #FF007F;
}

.custom-checkbox .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 0, 127, 0.25); /* Pink shadow when focused */
}

/* Adjust offcanvas panel for both opening and closing */
.offcanvas {
  transition: transform 0.6s ease-in-out;
}


