/* Buttons move effect on hover */
.hero-buttons .btn {
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .hero-buttons .btn:hover {
    transform: translateY(-5px); /* Slightly lifts the button */
    background-color: #FF3399; /* Optional: Change the button color on hover */
  }
  
  .hero-image img {
    filter: grayscale(100%);
    transition: filter 3s ease;
  }
  
  .hero-image img:hover {
    filter: grayscale(0%);
    transition: filter 1s ease;
  }
  