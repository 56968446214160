body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #101f36;
    color: #FFFFFF;
    overflow: hidden; /* Prevents scroll */
  }
  
  .bubble-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
  }
  
  .bubble {
    position: absolute;
    bottom: -100px;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 0, 127, 0.3); /* Pink transparent */
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgba(255, 0, 127, 0.3); /* Soft glow */
    animation: rise 15s infinite ease-in;
  }
  
  .bubble:nth-child(1) {
    opacity: 0.5; /* Ensures the first bubble is transparent */
  }
  
  .bubble:nth-child(2) {
    left: 20%;
    width: 60px;
    height: 60px;
    animation-duration: 18s;
  }
  
  .bubble:nth-child(3) {
    left: 40%;
    width: 30px;
    height: 30px;
    animation-duration: 12s;
    animation-delay: 2s;
  }
  
  .bubble:nth-child(4) {
    left: 60%;
    width: 50px;
    height: 50px;
    animation-duration: 20s;
    animation-delay: 4s;
  }
  
  .bubble:nth-child(5) {
    left: 80%;
    width: 70px;
    height: 70px;
    animation-duration: 22s;
    animation-delay: 6s;
  }
  
  @keyframes rise {
    0% {
      transform: translateY(0);
      opacity: 0.8;
    }
    100% {
      transform: translateY(-100vh);
      opacity: 0;
    }
  }
  



  /* Particle effect */
  #particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1; /* Behind all content */
  }
  